import { Image, Card, CardHeader } from "@nextui-org/react";
import ImagePerfilTest2 from "@assets/perfil-test2.png";
import ImageStyle from "../../Users/components/CreateUser/styled-component/ProfileImageStyle";
import Text from "@components/Text/Text";
import CardContainer from "./CardContainer";
import Title from "@components/Title/Title";
import SubTitle from "@components/SubTitle/SubTitle";

export const CardUserProfile = (user) => (
  <CardContainer>
    <Image src={ImagePerfilTest2} width={80} height={80} style={ImageStyle} />
    <Text size="16px" weight="600" style={{ paddingTop: "20px" }}></Text>
    <Title> {user?.name + " " + user?.lastName} </Title>
    <Text color="#41c564">{user?.email}</Text>
    <SubTitle style={{ paddingTop: "15px" }}>
      {user?.role === "ADMIN" ? "ADMIN" : "ESTUDIANTE"}
    </SubTitle>
  </CardContainer>
);
