import React from "react";
import { CheckboxGroup } from "@nextui-org/react";
import { CustomCheckbox } from "./CustomCheckBox";

function Installment({ paymentPlan, duesSelected, setDuesSelected }) {

    const getDateFormated = (date) => {
        const dateValue = new Date(date);
        return dateValue.getDate() + '/' + "" + (dateValue.getMonth() + 1) + "/" + dateValue.getFullYear();
    }

    const onSelectedDue = (target) => {
        setDuesSelected(target);
    }


    return (
        <div className="flex flex-col gap-1 w-full pt-4">
            <CheckboxGroup
                value={duesSelected}
                onChange={onSelectedDue}
                classNames={{
                    base: "w-full"
                }}
            >
                {paymentPlan?.map((plan, index) => (
                    <CustomCheckbox
                        value={plan?.id}
                        plan={{
                            price: plan?.cost,
                            date: plan?.paymentDate ? getDateFormated(plan?.paymentDate) : '',
                            expirationDate: plan?.expirationDate ? getDateFormated(plan?.expirationDate) : '',
                            status: plan?.status === 'true' ? 'Pago' : 'No Pago',
                            index: index
                        }}
                        statusColor={plan?.status === 'true' ? 'success' : 'warning'}
                        isPaymentCompleted={plan?.status === 'true'}
                    />
                ))}
            </CheckboxGroup>
        </div>


    );
}

export default Installment;
